require('dotenv').config()

import { createStore } from "vuex";
import router from "../router";

export default createStore({
  state: {
    user: null,
    testMode: false,
    project: null,
    marzipanoScenes: null,
    marzipanoProjectData: [],
    mapboxProjectData: [],
    marzipanoCurrentLote: null,
    mapboxCurrentLote: null,
    tareas: [],
    projectDetail: {
      contactInfo: '',
      typeLotization: '',
      data: {
        lotesInfo: {
          features: [
          ],
          type: ''
        }
      },
      projectInfo: {
        client: '',
        projectName: '',
        description: ''
      },
    },
    zonaComercial: [],
    zonaComercialAux: [],
    searchResult: [],
    tarea: {
      id: "",
      manzana: "",
      lote: "",
      area: 0,
      estado: "",
    },
    loteSelected: {
      properties: {
        front: "",
        back: "",
        right: "",
        left: "",
        typology: "",
        manzana: "",
        lote: 0,
        state: 0
      },
      id: "",
      geometry: {
        coordinates: [],
        type: ""
      }
    },
    hotspot: {
      id: "",
      manzana: "",
      lote: "",
      area: 0,
      estado: "",
    },
    manzanas: [],
    states: [
      'reservado',
      'disponible',
      'vendido'
    ],
    contactInfo: {
      phoneNumber: "",
    },
    contactInfoTemp: {
      phoneNumber: "",
    },
    comercialZonecontactInfo: {
      phoneNumber: "",
    },
    comercialZonecontactInfoTemp: {
      phoneNumber: "",
    },
    index: 0,
    indexHComercial: 0,
    error: { tipo: null, mensaje: null },
    emailRemembered: "",
    currentRoute: 'home',
  },
  mutations: {
    setProject(state, payload) {
      if (payload.name == null) {
        router.push("/");
        return;
      }
      state.project = payload
    },
    setMarzipanoProjectData(state, payload) {
      let scenes = [...payload.scenes]
      let infoHotspots = []
      for (let i = 0; i < scenes.length; i++) {
        if (scenes[i].id === '0-panorama_general') {
          continue
        }
        // infoHotspots.push(scenes[i].infoHotspots)

        for (let j = 0; j < scenes[i].infoHotspots.length; j++) {
          infoHotspots.push(scenes[i].infoHotspots[j])
        }
      }

      state.marzipanoScenes = payload.scenes
      state.marzipanoProjectData = infoHotspots;
    },

    setMapboxProjectData(state, payload) {
      if (payload.features !== null) {
        const list = payload.features
        state.mapboxProjectData = list
      }
    },
    setError(state, payload) {
      if (payload === null) {
        return (state.error = { tipo: null, mensaje: null });
      }
      if (payload === "EMAIL_NOT_FOUND") {
        return (state.error = {
          tipo: "email",
          mensaje: "Email no registrado",
        });
      }
      if (payload === "INVALID_PASSWORD") {
        return (state.error = {
          tipo: "password",
          mensaje: "Contraseña incorrecta",
        });
      }
      if (payload === "EMAIL_EXISTS") {
        return (state.error = {
          tipo: "email",
          mensaje: "Email ya registrado",
        });
      }
      if (payload === "INVALID_EMAIL") {
        return (state.error = {
          tipo: "email",
          mensaje: "Formato incorrecto de email",
        });
      }
    },
    setErrorForgotPassword(state, payload) {
      if (payload === null) {
        return (state.error = { tipo: null, mensaje: null });
      }
      if (payload === "EMAIL_NOT_FOUND") {
        return (state.error = {
          tipo: "email",
          mensaje: "Si el email es correcto revise su bandeja de entrada o buzón de correo no deseado",
        });
      }
    },
    setUser(state, payload) {
      state.user = payload;
    },
    cargar(state, payload) {
      state.tareas = payload;
      state.searchResult = payload;
    },
    cargarProjectDetail(state, payload) {
      state.projectDetail = payload;
    },
    cargarZonaComercial(state, payload) {
      state.zonaComercial = payload;
      state.zonaComercialAux = payload;
    },
    setManzanas(state, payload) {
      const setManzanas = new Set(payload);
      state.manzanas = setManzanas;
    },
    cargarContactInfo(state, payload) {
      state.contactInfo = payload;
      state.contactInfoTemp = payload;
    },
    cargarZonaComercialContactInfo(state, payload) {
      state.comercialZonecontactInfo = payload;
      state.comercialZonecontactInfoTemp = payload;
    },
    // set(state, payload) {
    //   state.tareas.push(payload);
    // },
    // eliminar(state, payload) {
    //   state.tareas = state.tareas.filter((item) => item.id !== payload);
    // },
    tarea(state, payload) {

    },
    setMarzipanoCurrentLote(state, payload) {
      const { id } = payload;
      if (state.marzipanoProjectData == null) {
        router.push("/");
        return;
      }

      if (!state.marzipanoProjectData.find((item) => item.id == id)) {
        router.push("/");
        return;
      }

      state.marzipanoCurrentLote = state.marzipanoProjectData.find((item) => item.id == id)
    },
    setMapboxCurrentLote(state, payload) {
      const { id, projectId } = payload;
      if (state.mapboxProjectData == null) {
        router.push(`/${projectId}`);
        return;
      }

      if (!state.mapboxProjectData.find((item) => item.id == id)) {
        router.push(`/${projectId}`);
        return;
      }
      state.mapboxCurrentLote = state.mapboxProjectData.find((item) => item.id == id)
    },
    hotspot(state, payload) {
      const { id, source, typeProject } = payload;

      if (typeProject == 'map') {
        if (!state.projectDetail.data.lotesInfo.features.find((item) => item.id == id)) {
          // TODO send message
          router.push("/proyectos");
          return;
        }
        state.loteSelected = state.projectDetail.data.lotesInfo.features.find((item) => item.id == id);
        return;
      }



      if (source == 'comercial') {
        if (!state.zonaComercial.find((item) => item.id == id)) {
          router.push("/");
          return;
        }
        state.hotspot = state.zonaComercial.find((item) => item.id == id);
      } else {
        if (!state.tareas.find((item) => item.id == id)) {
          router.push("/");
          return;
        }
        state.hotspot = state.tareas.find((item) => item.id == id);
      }
    },
    index(state, payload) {
      const { id, source, typeProject } = payload;

      if (typeProject == 'map') {
        state.index = state.projectDetail.data.lotesInfo.features.findIndex(e => e.id == id);
        return;
      }

      if (source == 'comercial') {
        state.index = state.zonaComercial.findIndex(e => e.id == id);
      } else {
        state.index = state.tareas.findIndex(e => e.id == id);
      }
    },
    update(state, payload) {
      state.tareas = state.tareas.map((item) =>
        item.id == payload.id ? payload : item
      );
      router.push("/");
    },
    updateZonaComercial(state, payload) {
      state.zonaComercial = state.zonaComercial.map((item) =>
        item.id == payload.id ? payload : item
      );
      router.push("/");
    },
    updateLoteInfo(state, payload) {
      // TODO Manage error
      const { projectId } = payload;
    },
    contactInfoTemp(state, payload) {
      const { vivienda, comercial } = payload;
      if (vivienda == null && comercial == null) {
        router.push("/");
        return;
      }

      if (state.currentTab == 'tab-Vivienda') {
        state.contactInfoTemp = Object.assign({}, vivienda);
      } else {
        state.comercialZonecontactInfoTemp = Object.assign({}, comercial);
      }

    },
    filtrar(state, payload) {
      const { manzana, estado } = payload;
      try {

        if (manzana == "all" && estado == "all") {
          state.searchResult = state.tareas;
          return;
        }

        state.searchResult = state.tareas.filter(function (item) {

          return (manzana == "all" ? true : item.manzana === manzana) &&
            (estado == "all" ? true : item.estado === estado);

        });

      } catch (error) {
        // console.log(error);
      }
    },
    filtrarZonaComercial(state, payload) {
      const { estado } = payload;
      try {

        if (estado == "all") {
          state.zonaComercialAux = state.zonaComercial;
          return;
        }

        state.zonaComercialAux = state.zonaComercial.filter(function (item) {

          return (estado == "all" ? true : item.estado === estado);

        });

      } catch (error) {
        // console.log(error);
      }
    },
    setEmailRemembered(state, payload) {
      state.emailRemembered = payload;
    },
    currentRoute(state, payload) {
      state.currentRoute = payload;
    },
    currentTab(state, payload) {
      state.currentTab = payload
    },

  },
  actions: {
    async fetchProject({ commit }, value) {
      const mainPath = this.state.testMode ? 'test-qa' : 'test';
      try {
        const { projectId } = value
        const token = this.state.user.idToken
        const res = await fetch(
          `https://lienzo-web360-default-rtdb.firebaseio.com/${mainPath}/projects/${projectId}.json?auth=${token}`
        )
        const data = await res.json();
        commit('setProject', data)
      } catch (e) {
        console.log(e);
      }
    },
    async fetchLotesData({ commit }, value) {
      const mainPath = this.state.testMode ? 'test-qa' : 'test';
      try {
        const lotizationType = this.state.project.lotizationType
        console.log(lotizationType)
        let typeData = ''

        if (lotizationType == 'marzipano') {
          typeData = 'marzipanoData'
        }

        if (lotizationType == 'mapbox') {
          typeData = 'mapboxData'
        }

        if (typeData == '') {
          throw 'Tipo de lotización desconocida, no se puede obtener la data'
        }

        const { projectId, } = value
        const res = await fetch(
          `https://lienzo-web360-default-rtdb.firebaseio.com/${mainPath}/${typeData}/${projectId}/data.json`
        )
        const data = await res.json();

        if (lotizationType == 'marzipano') {
          commit('setMarzipanoProjectData', data)

        } else {
          commit('setMapboxProjectData', data)
        }


      } catch (e) {
        console.log(e);
      }
    },
    async putContactInfo({ commit }, value) {
      const mainPath = this.state.testMode ? 'test-qa' : 'test'
      try {
        const { projectId, info } = value
        const token = this.state.user.idToken
        const res = await fetch(
          `https://lienzo-web360-default-rtdb.firebaseio.com/${mainPath}/contactInfo/${projectId}.json?auth=${token}`,
          {
            method: "PATCH",
            body: JSON.stringify({
              countryCode: info.countryCode,
              message: info.message,
              phoneNumber: info.phoneNumber
            })
          }
        )
        const dataDB = await res.json();

        if (dataDB.error == 'Permission denied') {
          console.log('No tiene permiso para editar esta información')
          router.push(`/${projectId}`);
          return;
        }

        if (dataDB.error) {
          commit("setUser", null);
          router.push("/ingreso");
          localStorage.removeItem("usuario");
          return;
        }

        router.push(`/${projectId}`)
      } catch (e) {
        console.log(e);
      }
    },
    setMapboxCurrentLote({ commit }, value) {
      commit('setMapboxCurrentLote', value)
    },
    setMarzipanoCurrentLote({ commit }, value) {
      commit('setMarzipanoCurrentLote', value)
    },
    setFilter({ commit }, value) {
      commit("filtrar", value);
    },
    setFilterZonaComercial({ commit }, value) {
      commit("filtrarZonaComercial", value);
    },
    cerrarSesion({ commit }) {
      commit("setUser", null);
      router.push("/ingreso");
      localStorage.removeItem("usuario");
    },
    async getUserData({ commit }, data) {
      try {
        const res = await fetch(
          "https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=AIzaSyCq4plJ-1kzScEmtFODpx0QQuTq4IIQIWE",
          {
            method: "POST",
            body: JSON.stringify({
              idToken: this.state.user.idToken
            })
          })
        const userDB = await res.json();

        if (userDB.error) {
          commit("setUser", null);
          router.push("/ingreso");
          localStorage.removeItem("usuario");
        }
      } catch (error) {
        // TODO
        commit("setUser", null);
        router.push("/ingreso");
        localStorage.removeItem("usuario");
      }
    },
    async passwordReset({ commit }, data) {
      const { email } = data;
      try {
        const res = await fetch(
          "https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyCq4plJ-1kzScEmtFODpx0QQuTq4IIQIWE",
          {
            method: "POST",
            body: JSON.stringify({
              requestType: "PASSWORD_RESET",
              email: email,
            }),
          })

        commit("setErrorForgotPassword", res.error)
      } catch (error) {

      }
    },
    async ingresoUsuario({ commit }, data) {
      const { usuario, rememberEmail } = data;

      try {
        const res = await fetch(
          "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCq4plJ-1kzScEmtFODpx0QQuTq4IIQIWE",
          {
            method: "POST",
            body: JSON.stringify({
              email: usuario.email,
              password: usuario.password,
              returnSecureToken: true,
            }),
          }
        );
        const userDB = await res.json();

        if (userDB.error) {
          // console.log(userDB.error);
          return commit("setError", userDB.error.message);
        }
        commit("setUser", userDB);
        commit("setError", null);
        if (rememberEmail) {
          commit("setEmailRemembered", usuario.email);
          localStorage.setItem("email", JSON.stringify(usuario.email))
        } else {
          commit("setEmailRemembered", "");
          localStorage.removeItem("email");
        }
        router.push("/");
        localStorage.setItem("usuario", JSON.stringify(userDB));
      } catch (error) {
        // console.log(error);
      }
    },
    async cargarLocalStorage({ commit }) {
      if (localStorage.getItem("usuario")) {
        commit("setUser", JSON.parse(localStorage.getItem("usuario")));
      } else {
        return commit("setUser", null);
      }
      try {
        const res = await fetch(
          `https://lienzo-web360-default-rtdb.firebaseio.com/projects/L2023-103/data.json`
        );
        const dataDB = await res.json();
        const arrayTareas = [];
        const manzanas = [];
        const contactInfo = dataDB.infoContacto;
        for (let id in dataDB.scenes[0].infoHotspots) {
          arrayTareas.push(dataDB.scenes[0].infoHotspots[id]);
          manzanas.push(dataDB.scenes[0].infoHotspots[id].manzana)
        }

        commit("cargar", arrayTareas);
        commit("setManzanas", manzanas);
        commit("cargarContactInfo", contactInfo);
      } catch (error) {
        // console.log(error);
      }
    },
    async cargarZonaComercial({ commit }) {
      try {
        const res = await fetch(
          'https://lienzo-web360-default-rtdb.firebaseio.com/projects/L2023-104/data.json'
        );
        const dataDB = await res.json();
        const arrayData = [];
        // TODO Load info zone
        const contactInfo = dataDB.infoContacto;
        for (let id in dataDB.scenes[0].infoHotspots) {
          arrayData.push(dataDB.scenes[0].infoHotspots[id]);
        }
        commit("cargarZonaComercial", arrayData);
        commit("cargarZonaComercialContactInfo", contactInfo);
      } catch (error) {
        // console.log(error);
      }
    },
    // setTarea({ commit }, id) {
    //   commit("tarea", id);
    //   commit("index", id);
    // },
    setHotspot({ commit }, data) {
      commit("hotspot", data);
      commit("index", data);
    },
    setError({ commit }, data) {
      commit("setError", data);
    },
    setProjectDetail({ commit }, data) {
      commit('cargarProjectDetail', data)
    },
    setContactInfo({ commit }, payload) {
      commit("contactInfoTemp", payload);
    },
    async updateTarea({ commit }, object) {
      try {
        const res = await fetch(
          `https://lienzo-web360-default-rtdb.firebaseio.com/projects/L2023-103/data/scenes/0/infoHotspots/${object.index}.json`,
          {
            method: "PATCH",
            body: JSON.stringify(object.hotspot),
          }
        );
        const dataDB = await res.json();
        commit("update", dataDB);
      } catch (error) {
        // console.log(error);
      }
    },
    async updateHotspotComercial({ commit }, object) {
      try {
        const res = await fetch(
          `https://lienzo-web360-default-rtdb.firebaseio.com/projects/L2023-104/data/scenes/0/infoHotspots/${object.index}.json`,
          {
            method: "PATCH",
            body: JSON.stringify(object.hotspot),
          }
        );
        const dataDB = await res.json();
        commit("updateZonaComercial", dataDB);
      } catch (error) {
        // console.log(error);
      }
    },
    async updateLoteInfo({ commit }, data) {
      const { projectId, lote, loteId } = data;
      const token = this.state.user.idToken
      const index = this.state.mapboxProjectData.findIndex((item) => item.id == loteId)

      try {
        const res = await fetch(
          `https://lienzo-web360-default-rtdb.firebaseio.com/test/mapboxData/${projectId}/data/features/${index}.json?auth=${token}`,
          {
            method: "PATCH",
            body: JSON.stringify(lote),
          }
        );
        const dataDB = await res.json();
        // commit("updateLoteInfo", data);
        router.push(`/${projectId}`);
      } catch (error) {
        console.log(error);
      }
    },
    async updateMapboxLoteInfo({ commit }, data) {
      const mainPath = this.state.testMode ? 'test-qa' : 'test'
      const { projectId, lote, loteId } = data;
      const token = this.state.user.idToken
      // TODO
      const loteIndex = this.state.mapboxProjectData.findIndex((item) => item.id == loteId)

      if (loteIndex < 0) {
        throw 'No se encontró index de lote'
      }
      try {
        const res = await fetch(
          `https://lienzo-web360-default-rtdb.firebaseio.com/${mainPath}/mapboxData/${projectId}/data/features/${loteIndex}.json?auth=${token}`,
          {
            method: "PATCH",
            body: JSON.stringify(lote),
          }
        );
        const dataDB = await res.json();

        if (dataDB.error == 'Permission denied') {
          console.log('No tiene permiso para editar esta información')
          router.push(`/${projectId}`);
          return;
        }

        if (dataDB.error) {
          commit("setUser", null);
          router.push("/ingreso");
          localStorage.removeItem("usuario");
          return;
        }
        router.push(`/${projectId}`);
      } catch (error) {
        console.log(error);
        commit("setUser", null);
        router.push("/ingreso");
        localStorage.removeItem("usuario");
      }
    },
    async updateMarzipanoLoteInfo({ commit }, data) {
      const { projectId, lote, loteId } = data;
      const token = this.state.user.idToken
      // TODO
      const sceneIndex = this.state.marzipanoScenes.findIndex((scene) => (scene.infoHotspots ?? []).find((lote) => lote.id == loteId))
      if (sceneIndex < 0) {
        throw 'No se encontró index de scena'
      }

      const loteIndex = (this.state.marzipanoScenes[sceneIndex].infoHotspots ?? []).findIndex((item) => item.id == loteId)

      try {
        const res = await fetch(
          `https://lienzo-web360-default-rtdb.firebaseio.com/test/marzipanoData/${projectId}/data/scenes/${sceneIndex}/infoHotspots/${loteIndex}.json?auth=${token}`,
          {
            method: "PATCH",
            body: JSON.stringify(lote),
          }
        );
        const dataDB = await res.json();
        // commit("updateLoteInfo", data);
        if (dataDB.error) {
          commit("setUser", null);
          router.push("/ingreso");
          localStorage.removeItem("usuario");
          return;
        }
        router.push(`/${projectId}`);
      } catch (error) {
        console.log(error);
        commit("setUser", null);
        router.push("/ingreso");
        localStorage.removeItem("usuario");
      }
    },
    async updateContactInfo({ commit }, contactInfo) {


      let code = '';
      if (this.state.currentTab == 'tab-Vivienda') {
        code = '3';
      } else {
        code = '4';
      }
      try {
        const res = await fetch(
          `https://lienzo-web360-default-rtdb.firebaseio.com/projects/L2023-10${code}/data/infoContacto.json`,
          {
            method: "PATCH",
            body: JSON.stringify(contactInfo),
          }
        );
        const data = await res.json();

        if (this.state.currentTab == 'tab-Vivienda') {
          commit('cargarContactInfo', data);

        } else {
          commit("cargarZonaComercialContactInfo", data);
        }


        router.push("/");
      } catch (error) {
        // console.log(error);
      }
    },
    async loadUserPreferences({ commit }) {
      if (localStorage.getItem("email")) {
        commit("setEmailRemembered", JSON.parse(localStorage.getItem("email")));
      }
    },
    loadCurrentTab({ commit }) {
      if (localStorage.getItem("currentTab")) {
        commit('currentTab', JSON.parse(localStorage.getItem("currentTab")))
      }
    },
    setCurrentRoute({ commit }, route) {
      commit('currentRoute', route);
    },
    setCurrentTab({ commit }, tabName) {
      localStorage.setItem("currentTab", JSON.stringify(tabName))
      commit('currentTab', tabName);
    }
  },
  getters: {
    usuarioAutenticado(state) {
      return !!state.user;
    },
    mainPath(state) {
      return state.testMode ? 'test-qa' : 'test';
    }

  },
  modules: {},
});
