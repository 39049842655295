import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { rutaProtegida: true }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/:projectId',
    name: 'Project',
    component: () => import(/* webpackChunkName: "about" */ '../views/Project.vue')
  },
  // {
  //   path: '/proyectos',
  //   name: 'Proyectos',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Projects.vue')
  // },
  // {
  //   path: '/:source/:projectId',
  //   name: 'DetailProject',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/ProjectDetail.vue'),
  //   meta: { rutaProtegida: true }
  // },
  {
    path: '/:projectId/editar/:lotizationType/:id',
    name: 'Editar',
    component: () => import(/* webpackChunkName: "about" */ '../views/Editar.vue'),
    meta: { rutaProtegida: true }
  },
  {
    path: '/registro',
    name: 'Registro',
    component: () => import(/* webpackChunkName: "about" */ '../views/Registro.vue'),
    meta: { rutaProtegida: false }
  },
  {
    path: '/ingreso',
    name: 'Ingreso',
    component: () => import(/* webpackChunkName: "about" */ '../views/Ingreso.vue'),
    meta: { rutaProtegida: false }
  },
  {
    path: '/:projectId/configuracion',
    name: 'ProjectSettings',
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings.vue'),
    meta: { rutaProtegida: true }
  },
  // {
  //   path: '/settings',
  //   name: 'Settings',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Settings.vue'),
  //   meta: { rutaProtegida: true }
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.rutaProtegida) {
    if (store.getters.usuarioAutenticado) {
      next()
    } else {
      next('/ingreso')
    }
  } else {
    next()
  }
})

export default router
