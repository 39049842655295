<template>

  <div class="container">
    <h2 class="mt-2 mb-4">Proyectos</h2>
    <!-- Tabla de proyectos -->
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Proyecto</th>
          <th scope="col">Cliente</th>
          <th scope="col">Cantidad de lotes</th>
          <th></th>
        </tr>
      </thead>
      <tbody>

        <tr v-for="(project, index) in myProjects" :key="index">
          <td>{{ project.name }}</td>
          <td>{{ project.client }}</td>
          <td>{{ project.totalPlots }}</td>
          <td> <router-link class="btn btn-primary" :to="{
            name: 'Project',
            params: {
              projectId: index,
            }
          }">
              Abrir
            </router-link> </td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'Home',
  data() {
    return {
      projects: null

    }
  },
  computed: {
    ...mapGetters(['mainPath']),
    ...mapState(['user', 'testMode']),
    myProjects() {

      for (let project in this.projects) {
        if (this.projects[project].users === undefined) {
          delete this.projects[project];
          continue
        }

        if (this.projects[project].users === null) {
          delete this.projects[project];
          continue
        }

        if (this.projects[project].users[this.user.localId] !== true) {
          delete this.projects[project];
        }
      }
      return this.projects
    }
  },
  methods: {
    ...mapActions(['setHostpot', 'getUserData']),
    // cargar lista de proyectos a los que el usuario tiene acceso
    async fetchProjects() {
      try {
        const token = this.user.idToken
        const res = await fetch(
          `https://lienzo-web360-default-rtdb.firebaseio.com/${this.mainPath}/projects.json?auth=${token}`

        )
        const data = await res.json();
        this.projects = data;
        // this.setProjects(this.myProjects)
      } catch (e) {
        console.log(e);
      }


    }
  },
  async created() {
    this.getUserData();
    await this.fetchProjects();

  }

}
</script>

<style scoped>

</style>
